import React  from "react";
import PropTypes from "prop-types";
import Seo from "../components/Seo";
import LayoutInner from "../components/LayoutInner";
import Section from "../components/Section";
import Cover from "../components/Cover";

const NotFoundPage = ({ location }) => {
  return (
  <LayoutInner location={location}>
    <Seo title="404: Not found" />
    <div className="pageWrapper">
        <div className="pageCover">
          <Cover/>
        </div>
        <div className="pageContent">
        <Section className="inner not-found">
          <div className="inner-wrap">
            <h1>404: Page not found</h1>
            You just hit a route that doesn&#39;t exist... the sadness.
            </div>
        </Section>
        </div>
      </div>
  </LayoutInner>
)};

NotFoundPage.propTypes = {
  location: PropTypes.string,
};


export default NotFoundPage;
